import { ColorModeScript } from '@chakra-ui/react'
import { theme } from '@irishlife/ilgroupdesignsystem.theme.theme'
import { ThemeProvider } from '@irishlife/ilgroupdesignsystem.theme.theme-provider'
import React from 'react'
import { RecoilRoot } from 'recoil'
import { COOKIEBOT_ID, GTM_ID } from '../src/core/config'
import { CSSReset } from '../src/css-reset'

const cookieBotScript = (
    <script
        defer
        id='Cookiebot'
        key='Cookiebot'
        src='https://consent.cookiebot.com/uc.js'
        data-cbid={COOKIEBOT_ID}
        data-blockingmode='auto'
        type='text/javascript'
    ></script>
)
const googleConsentScript = (
    <script
        src='/GoogleConsentMode.js'
        data-cookieconsent='ignore'
        type='text/javascript'
    ></script>
)

const gtmScript = (
    <script
        async
        data-cookieconsent='ignore'
        dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');`,
        }}
        key='GTM'
    />
)

export const optimizelyScript = (
    <script
        key='optimizely'
        data-blockingmode='auto'
        src='https://cdn.optimizely.com/js/27432340403.js'
        type='text/javascript'
    />
)

export const wrapRootElement = ({ element, props }) => (
    <ThemeProvider resetCSS={false}>
        <CSSReset />
        <RecoilRoot {...props}>{element}</RecoilRoot>
    </ThemeProvider>
)

export const onRenderBody = ({ setHeadComponents, setPreBodyComponents }) => {
    setHeadComponents([
        googleConsentScript,
        gtmScript,
        cookieBotScript,
        optimizelyScript,
    ])
    setPreBodyComponents([
        <ColorModeScript
            initialColorMode={theme.config.initialColorMode}
            key='chakra-ui-no-flash'
        />,
    ])
}

export const onPreRenderHTML = ({
    getPostBodyComponents,
    replacePostBodyComponents,
}) => {
    const postBodyComponents = getPostBodyComponents()
    replacePostBodyComponents(
        postBodyComponents.map((script) => ({
            ...script,
            props: { ...script.props, 'data-cookieconsent': 'ignore' },
        }))
    )
}
