// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-common-pages-blog-landing-page-tsx": () => import("./../../../src/common/pages/BlogLandingPage.tsx" /* webpackChunkName: "component---src-common-pages-blog-landing-page-tsx" */),
  "component---src-common-pages-blog-post-page-tsx": () => import("./../../../src/common/pages/BlogPostPage.tsx" /* webpackChunkName: "component---src-common-pages-blog-post-page-tsx" */),
  "component---src-common-pages-calculator-page-tsx": () => import("./../../../src/common/pages/CalculatorPage.tsx" /* webpackChunkName: "component---src-common-pages-calculator-page-tsx" */),
  "component---src-common-pages-campaign-landing-medium-page-tsx": () => import("./../../../src/common/pages/CampaignLandingMediumPage.tsx" /* webpackChunkName: "component---src-common-pages-campaign-landing-medium-page-tsx" */),
  "component---src-common-pages-campaign-landing-page-tsx": () => import("./../../../src/common/pages/CampaignLandingPage.tsx" /* webpackChunkName: "component---src-common-pages-campaign-landing-page-tsx" */),
  "component---src-common-pages-campaign-landing-regular-page-tsx": () => import("./../../../src/common/pages/CampaignLandingRegularPage.tsx" /* webpackChunkName: "component---src-common-pages-campaign-landing-regular-page-tsx" */),
  "component---src-common-pages-compare-funds-page-tsx": () => import("./../../../src/common/pages/CompareFundsPage.tsx" /* webpackChunkName: "component---src-common-pages-compare-funds-page-tsx" */),
  "component---src-common-pages-contact-us-existing-page-tsx": () => import("./../../../src/common/pages/ContactUsExistingPage.tsx" /* webpackChunkName: "component---src-common-pages-contact-us-existing-page-tsx" */),
  "component---src-common-pages-contact-us-others-page-tsx": () => import("./../../../src/common/pages/ContactUsOthersPage.tsx" /* webpackChunkName: "component---src-common-pages-contact-us-others-page-tsx" */),
  "component---src-common-pages-contact-us-purchasing-page-tsx": () => import("./../../../src/common/pages/ContactUsPurchasingPage.tsx" /* webpackChunkName: "component---src-common-pages-contact-us-purchasing-page-tsx" */),
  "component---src-common-pages-error-page-tsx": () => import("./../../../src/common/pages/ErrorPage.tsx" /* webpackChunkName: "component---src-common-pages-error-page-tsx" */),
  "component---src-common-pages-fund-centre-page-tsx": () => import("./../../../src/common/pages/FundCentrePage.tsx" /* webpackChunkName: "component---src-common-pages-fund-centre-page-tsx" */),
  "component---src-common-pages-fund-details-page-tsx": () => import("./../../../src/common/pages/FundDetailsPage.tsx" /* webpackChunkName: "component---src-common-pages-fund-details-page-tsx" */),
  "component---src-common-pages-help-centre-page-tsx": () => import("./../../../src/common/pages/HelpCentrePage.tsx" /* webpackChunkName: "component---src-common-pages-help-centre-page-tsx" */),
  "component---src-common-pages-home-page-tsx": () => import("./../../../src/common/pages/HomePage.tsx" /* webpackChunkName: "component---src-common-pages-home-page-tsx" */),
  "component---src-common-pages-illnesses-covered-page-tsx": () => import("./../../../src/common/pages/IllnessesCoveredPage.tsx" /* webpackChunkName: "component---src-common-pages-illnesses-covered-page-tsx" */),
  "component---src-common-pages-income-tax-relief-page-tsx": () => import("./../../../src/common/pages/IncomeTaxReliefPage.tsx" /* webpackChunkName: "component---src-common-pages-income-tax-relief-page-tsx" */),
  "component---src-common-pages-introduction-page-tsx": () => import("./../../../src/common/pages/IntroductionPage.tsx" /* webpackChunkName: "component---src-common-pages-introduction-page-tsx" */),
  "component---src-common-pages-kids-page-tsx": () => import("./../../../src/common/pages/KidsPage.tsx" /* webpackChunkName: "component---src-common-pages-kids-page-tsx" */),
  "component---src-common-pages-maps-intro-page-tsx": () => import("./../../../src/common/pages/MapsIntroPage.tsx" /* webpackChunkName: "component---src-common-pages-maps-intro-page-tsx" */),
  "component---src-common-pages-priips-page-tsx": () => import("./../../../src/common/pages/PriipsPage.tsx" /* webpackChunkName: "component---src-common-pages-priips-page-tsx" */),
  "component---src-common-pages-product-clear-invest-page-tsx": () => import("./../../../src/common/pages/ProductClearInvestPage.tsx" /* webpackChunkName: "component---src-common-pages-product-clear-invest-page-tsx" */),
  "component---src-common-pages-product-lifecare-page-tsx": () => import("./../../../src/common/pages/ProductLifecarePage.tsx" /* webpackChunkName: "component---src-common-pages-product-lifecare-page-tsx" */),
  "component---src-common-pages-product-page-tsx": () => import("./../../../src/common/pages/ProductPage.tsx" /* webpackChunkName: "component---src-common-pages-product-page-tsx" */),
  "component---src-common-pages-product-pensions-page-tsx": () => import("./../../../src/common/pages/ProductPensionsPage.tsx" /* webpackChunkName: "component---src-common-pages-product-pensions-page-tsx" */),
  "component---src-common-pages-sfdr-page-tsx": () => import("./../../../src/common/pages/SfdrPage.tsx" /* webpackChunkName: "component---src-common-pages-sfdr-page-tsx" */),
  "component---src-common-pages-simple-text-page-tsx": () => import("./../../../src/common/pages/SimpleTextPage.tsx" /* webpackChunkName: "component---src-common-pages-simple-text-page-tsx" */),
  "component---src-common-pages-template-generic-page-tsx": () => import("./../../../src/common/pages/TemplateGenericPage.tsx" /* webpackChunkName: "component---src-common-pages-template-generic-page-tsx" */),
  "component---src-common-pages-tools-hub-page-tsx": () => import("./../../../src/common/pages/ToolsHubPage.tsx" /* webpackChunkName: "component---src-common-pages-tools-hub-page-tsx" */)
}

