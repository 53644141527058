import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

export const theme = responsiveFontSizes(
    createMuiTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 0,
                md: 720,
                lg: 1024,
                xl: 1280,
            },
        },
        overrides: {
            MuiFab: {
                root: {
                    padding: '0px 24px !important',
                },
                label: {
                    fontSize: '18px !important',
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                },
                secondary: {
                    color: '#424FCF',
                    '&:hover': {
                        backgroundColor: '#5E6CDA',
                        color: 'white',
                    },
                    '&:active': {
                        backgroundColor: '#5261AC',
                        color: 'white',
                    },
                },
            },
            MuiCssBaseline: {
                '@global': {
                    a: {
                        fontWeight: 600,
                        color: '#5261AC',
                        textDecoration: 'none',
                    },
                    body: {
                        overflowX: 'hidden',
                        paddingRight: '0px !important',
                    },
                    header: {
                        paddingRight: '0px !important',
                    },
                    html: {
                        overflowX: 'hidden',
                        scrollBehavior: 'smooth',
                    },
                },
            },
        },
        palette: {
            background: { default: '#F7F8FB' },
            primary: { main: '#5261AC', dark: '#3A3160' },
            secondary: { main: '#E3E5F9' },
            text: { primary: '#3A3160', secondary: '#766F90' },
        },
        props: {
            MuiButton: {
                // color: 'primary',
                disableElevation: true,
                variant: 'outlined',
            },
        },
        shape: { borderRadius: 8 },
        spacing: 4,
        typography: {
            fontFamily: 'Assistant',
            h1: {
                lineHeight: 1.32,
                fontSize: 60,
                fontStyle: 'normal',
                fontWeight: 'normal',
            },
            h2: {
                lineHeight: 1.32,
                fontSize: 50,
                fontStyle: 'normal',
                fontWeight: 'normal',
            },
            h3: {
                lineHeight: 1.32,
                fontSize: 42,
                fontStyle: 'normal',
                fontWeight: 500,
            },
            h4: {
                fontSize: 36,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 1.32,
            },
            h5: {
                fontSize: 28,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 1.32,
            },
            h6: {
                fontSize: 24,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 1.32,
            },
            body1: {
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 1.56,
            },
            body2: {
                lineHeight: 1.56,
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
            },
            caption: {
                lineHeight: 1.32,
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
            },
            overline: {
                fontWeight: 600,
                fontSize: 16,
                lineHeight: 1.32,
                textTransform: 'uppercase',
            },
            subtitle1: {
                lineHeight: 1.56,
                fontSize: 18,
                fontStyle: 'normal',
                fontWeight: 500,
            },
            button: {
                lineHeight: 30 / 16,
                fontSize: 16,
                fontWeight: 600,
            },
        },
    })
)
