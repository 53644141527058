export const VERSION =
    process.env.GATSBY_APP_VERSION || process.env.REACT_APP_VERSION

export const PREVIEW_MODE = !process.env.GATSBY_PREVIEW_MODE

export const API_URL =
    process.env.GATSBY_API_URL || 'https://apps.qairishlife.ie'
export const API_TOKEN = '6da52967-b118-4a7c-94d0-dfa50e32a2ee'
export const ATTRIBUTION_URL =
    'https://az-ag-prd-web-attribution.azurewebsites.net/'
export const PREVIOUS_URL = 'https://previous.irishlife.ie'
export const URL = 'https://www.irishlife.ie'

// ANALYTICS & CONSENT
export const GA_ID = process.env.GATSBY_GA_ID || 'UA-16369698-22'
export const GTM_ID = process.env.GATSBY_GTM_ID || 'GTM-K889NLG'
export const COOKIEBOT_ID = '2f2acb6d-8c6e-4935-b48e-07e7913fef44'

// APP INSIGHTS
export const APP_INSIGHTS_INSTRUMENTATION_KEY =
    process.env.GATSBY_APP_INSIGHTS_INSTRUMENTATION_KEY ||
    '9cac2a09-c8d9-45eb-8218-1147c4f7b249'

// ADVISOR BOOKING
export const ADVISOR_BOOKING_TOKEN_URL =
    process.env.GATSBY_ADVISOR_BOOKING_TOKEN_URL ||
    'https://az-aa-asv-uz1-ilgroup-apiauth.azurewebsites.net/api/token/gettoken?appName=IL-AdvisorBooking&API_KEY=0B5C9ADD-DA35-4335-9FC7-A17A8913A11D'
export const ADVISOR_BOOKING_URL =
    process.env.GATSBY_ADVISOR_BOOKING_URL ||
    'https://uat.apipub.irishlife.ie/external/retail-digital-appointments/v1/api/v2/digitalappointments/'
export const ADVISOR_BOOKING_TEAM_NAME =
    process.env.GATSBY_ADVISOR_BOOKING_TEAM_NAME || 'DigitalSales'
export const ADVISOR_BOOKING_POR_TEAM_NAME =
    process.env.GATSBY_ADVISOR_BOOKING_POR_TEAM_NAME ||
    'Retirement Readiness Team'
export const ADVISOR_BOOKING_SERVICE_NAME =
    process.env.GATSBY_ADVISOR_BOOKING_SERVICE_NAME ||
    'Digital Appointments Service'
export const ADVISOR_BOOKING_SUBSCRIPTION_KEY =
    process.env.GATSBY_ADVISOR_BOOKING_SUBSCRIPTION_KEY ||
    '02b65fe1603d414fb3b72fec96ae05fd'
export const ADVISOR_BOOKING_API_KEY =
    process.env.GATSBY_ADVISOR_BOOKING_API_KEY ||
    '67de6222-4c97-4883-9c6a-8b65ad73eddd'

export const ADVISOR_BOOKING_FLAG = !(
    process.env.GATSBY_ADVISOR_BOOKING_FLAG === 'false'
)

// GENESYS / LEAD GENERATION
export const LEAD_GENERATION_TOKEN_URL =
    process.env.GATSBY_LEAD_GENERATION_TOKEN_URL ||
    'https://az-aa-asv-uz1-ilgroup-apiauth.azurewebsites.net/api/token/gettoken?appName=IL-WebCallBack&API_KEY=0B5C9ADD-DA35-4335-9FC7-A17A8913A11D'
export const LEAD_GENERATION_URL =
    process.env.GATSBY_LEAD_GENERATION_URL ||
    'https://uat.apipub.irishlife.ie/external/retail-lead-callback/v1/api/wrapup/createlead'
export const LEAD_GENERATION_SUBSCRIPTION_KEY =
    process.env.GATSBY_LEAD_GENERATION_SUBSCRIPTION_KEY ||
    '02b65fe1603d414fb3b72fec96ae05fd'
export const LEAD_GENERATION_API_KEY =
    process.env.GATSBY_LEAD_GENERATION_API_KEY ||
    'BD15EB4C-56D1-4481-B1D6-AC490610D72C'
// Control form flag
export const GENESYS_FLAG = process.env.GATSBY_GENESYS_FLAG === 'true'

// COLLEGE COSTS CALCULATOR
export const COLLEGE_COSTS_CALCULATOR_DIGITAL_ADVICE_URL =
    process.env.GATSBY_COLLEGE_COSTS_CALCULATOR_DIGITAL_ADVICE_URL ||
    'https://uat-dot-multiply-ireland.appspot.com/'
export const COLLEGE_COSTS_CALCULATOR_GRAPHQL_ENDPOINT =
    process.env.GATSBY_COLLEGE_COSTS_CALCULATOR_GRAPHQL_ENDPOINT ||
    'https://dev-dot-api-dot-multiply-ireland.nw.r.appspot.com/graphql'
